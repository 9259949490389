<template>
  <el-select
    v-model="selectVal"
    :placeholder="placeholder"
    size="small"
    @change="change"
    ref="keySelect"
    :disabled="disabled"
    multiple
    @visible-change="visibleChange"
  >
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
import { getProductBrand } from '@/api/toolkit';

export default {
  name: 'BrandSelect',
  props: {
    arr: {
      type: Array,
      default: () => []
    },
    val: {
      type: Array,
      default: () => []
    },
    valArrStr: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择品牌'
    }
  },
  watch: {
    val: {
      immediate: true,
      handler(newVal) {
        this.selectVal = newVal;
      }
    }
  },
  data() {
    return {
      selectVal: [],
      options: []
    };
  },
  mounted() {
    if (this.arr.length > 0 && this.val.length > 0) this.options = this.arr;
  },
  methods: {
    change() {
      this.$emit('update:val', this.selectVal);
      this.$emit('update:valArrStr', this.selectVal.join(','));

      this.$emit(
        'update:arr',
        this.options.filter((el) => this.selectVal.includes(el.value))
      );
    },
    async getList() {
      try {
        const { data: res } = await getProductBrand({ data: {} });
        this.options = (res || []).map((el) => ({
          label: el.brandName,
          value: el.productBrandId
        }));
      } catch (err) {
        console.error(err);
      }
    },
    visibleChange(visible) {
      if (visible) this.getList();
    }
  }
};
</script>

<style></style>
