<template>
  <div class="role-menu-list">
    <div class="tools-bar">
      <div class="left-search">
        <span class="label">当前角色：</span>
        <span class="role-name">{{ roleName }}</span>

        <span class="label">菜单端口：</span>
        <el-select
          v-model="search.nodePort"
          placeholder="请选择菜单端口"
          size="mini"
          class="select"
          @change="nodePortChange"
        >
          <el-option
            v-for="item in nodePortOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="right-buttons">
        <el-button size="mini" class="btn" @click="cnacelPage">取消</el-button>
        <el-button
          type="primary"
          size="mini"
          class="btn"
          @click="saveList"
          :loading="saveBtnLoading"
        >
          保存
        </el-button>
      </div>
    </div>

    <vxe-table
      border
      resizable
      :data="tableData"
      :checkbox-config="{
        labelField: 'nodeName'
      }"
      :row-config="{ keyField: 'nodeId' }"
      :tree-config="{
        transform: false,
        rowField: 'nodeId',
        parentField: 'parentId',
        childrenField: 'menuChildren'
      }"
      size="small"
      class="table-ex"
      ref="vxeMenuTable"
      v-loading="vxeTableLoading"
      v-if="search.nodePort == 1"
    >
      <vxe-column type="checkbox" title="菜单" width="280" tree-node></vxe-column>

      <vxe-column title="功能（操作）">
        <template #header="{ column }">
          <el-checkbox v-model="checkAll" @change="checkAllChange"></el-checkbox>
          <span style="margin-left: 6px">{{ column.title }}</span>
        </template>

        <template #default="{ row }">
          <el-checkbox
            v-model="item.actionSelected"
            v-for="item in row.actionList"
            :key="item.nodeId"
            @change="actionSeletedChange"
          >
            {{ item.nodeName }}
          </el-checkbox>
        </template>
      </vxe-column>

      <vxe-column title="配置" width="200px">
        <template #default="{ row }">
          <template v-if="row.isShowDataPermission == 1">
            <el-button type="text" size="small" @click="processItem(1, row)">数据选项</el-button>
            <el-button type="text" size="small" @click="processItem(2, row)">数据范围</el-button>
          </template>
        </template>
      </vxe-column>
    </vxe-table>

    <vxe-table
      border
      resizable
      :data="tableData"
      :checkbox-config="{ labelField: 'nodeName' }"
      :row-config="{ keyField: 'nodeId' }"
      :tree-config="{
        transform: false,
        rowField: 'nodeId',
        parentField: 'parentId',
        childrenField: 'actionQWChildren'
      }"
      size="small"
      class="table-ex"
      ref="vxeQWTable"
      v-loading="vxeTableLoading"
      v-if="search.nodePort == 2"
    >
      <vxe-column type="checkbox" title="功能（操作）" tree-node></vxe-column>
    </vxe-table>

    <DataOptions ref="DataOptions" @refresh="getList" :nodeId="currentNodeId" />
    <DataConditions ref="DataConditions" @refresh="getList" :nodeId="currentNodeId" />
  </div>
</template>

<script>
import { getUserPowerByRoleId, saveRolePermission } from '@/api/role';
import DataOptions from './components/DataOptions.vue';
import DataConditions from './components/DataConditions.vue';

export default {
  components: {
    DataOptions,
    DataConditions
  },
  data() {
    return {
      tableData: [],
      rawData: [],
      search: {
        nodePort: 1
      },
      nodePortOptions: [
        {
          value: 1,
          label: '运营端'
        },
        {
          value: 2,
          label: '企微端'
        }
      ],
      roleName: '',
      saveBtnLoading: false,
      vxeTableLoading: true,
      currentNodeId: '',
      checkAll: false
    };
  },
  computed: {
    roleId() {
      return this.$route.query.roleId || '';
    }
  },
  mounted() {
    this.roleId && this.getList();
  },
  methods: {
    processItem(type, row) {
      this.currentNodeId = row.nodeId;

      if (type == 1) {
        this.$refs.DataOptions.open();
      }

      if (type == 2) {
        this.$refs.DataConditions.open();
      }
    },
    getList() {
      if (!this.vxeTableLoading) this.vxeTableLoading = true;

      const params = { data: this.roleId };
      getUserPowerByRoleId(params)
        .then((res) => {
          const { roleNodeTree, roleName } = res || {};
          const raw = this.toMenuTree(roleNodeTree);
          this.rawData = raw || [];
          this.roleName = roleName || '';
          this.nodePortChange();
        })
        .catch((err) => console.error(err))
        .finally(() => (this.vxeTableLoading = false));
    },
    nodePortChange() {
      const nodePort = this.search.nodePort;
      const arr = this.rawData;

      this.tableData = arr
        .filter((el) => {
          if (nodePort == 2) return el.actionQWTop;
          return el.isMenuTop;
        })
        .sort((a, b) => a.sort - b.sort);

      //必须在$nextTick中执行选中操作，不然回显异常，坑逼库！！！！
      this.$nextTick(() => {
        //回显运营端菜单状态
        if (nodePort == 1) {
          const checkRows = arr.filter((el) => el.menuSelected && el.nodePort == nodePort);
          this.$refs.vxeMenuTable.setCheckboxRow(checkRows, true);
          this.reshowActionCheckedAll();
          return;
        }

        //回显企微端功能状态
        if (nodePort == 2) {
          const checkRows = arr.filter(
            (el) => el.actionSelected && el.nodePort == nodePort && !el.actionQWChildren.length
          );

          this.$refs.vxeQWTable.setCheckboxRow(checkRows, true);
          return;
        }
      });
    },
    toMenuTree(data = []) {
      if (!data || !Array.isArray(data)) return [];

      data.forEach((el) => {
        //菜单子级列表
        el.menuChildren = data.filter((x) => el.nodeId == x.parentId && x.nodeType == 1);

        //是否操作（功能）
        el.actionList = data.filter((x) => el.nodeId == x.parentId && x.nodeType == 2);

        //操作（功能）是否选中
        el.actionSelected = el.isSelect == 1 && el.nodeType == 2;

        //菜单叶子节点是否选中
        el.menuSelected = el.isSelect == 1 && el.nodeType == 1 && !el.menuChildren.length;

        //是否顶层菜单
        const top = el.parentId == 0 || !el.parentId || !data.some((x) => x.nodeId == el.parentId);
        el.isMenuTop = top && el.nodeType == 1 && el.nodePort == 1;

        //企微端功能子级列表
        el.actionQWChildren = data.filter(
          (x) => el.nodeId == x.parentId && x.nodeType == 2 && x.nodePort == 2
        );

        //是否企微顶层操作（功能）
        el.actionQWTop = (!el.parentId || el.parentId == 0) && el.nodeType == 2 && el.nodePort == 2;
      });

      return data;
    },
    saveList() {
      const nodePort = this.search.nodePort;

      const tableRef = nodePort != 2 ? this.$refs.vxeMenuTable : this.$refs.vxeQWTable;
      const { getCheckboxRecords, getCheckboxIndeterminateRecords } = tableRef;
      const records = [...getCheckboxRecords(), ...getCheckboxIndeterminateRecords()];

      const tableTreeObjArr = records.map((el) => {
        return {
          nodeId: el.nodeId,
          isNeedDataPermission: el.isNeedDataPermission,
          dataPermission: el.dataPermission || []
        };
      });

      const actionObjArr = [];
      if (nodePort == 1) {
        const resActionArr = this.rawData
          .filter((x) => x.actionSelected && x.nodeType == 2 && x.nodePort == 1)
          .map((el) => {
            return {
              nodeId: el.nodeId,
              isNeedDataPermission: el.isNeedDataPermission,
              dataPermission: el.dataPermission || []
            };
          });

        actionObjArr.push(...resActionArr);
      }

      const params = {
        data: {
          roleId: this.roleId,
          nodePort,
          roleNodes: [...tableTreeObjArr, ...actionObjArr]
        }
      };

      this.saveBtnLoading = true;
      saveRolePermission(params)
        .then((res) => {
          if (!res) return;
          this.getList();
          this.$message.success('操作成功');
        })
        .catch((err) => console.log(err))
        .finally(() => (this.saveBtnLoading = false));
    },
    cnacelPage() {
      this.$router.back();
    },
    checkAllChange() {
      this.rawData
        .filter((el) => el.nodePort == this.search.nodePort && el.nodeType == 2)
        .forEach((x) => {
          x.actionSelected = this.checkAll;
        });

      // this.nodePortChange(); //这样也可以成功设置操作权限，但是会影响菜单权限选中
      this.$forceUpdate(); //由于树层级关系所以采取重新渲染，这个不会影响菜单权限
    },
    reshowActionCheckedAll() {
      //回显操作是否有全部选中
      this.checkAll = !this.rawData.some(
        (x) => x.nodeType == 2 && x.nodePort == this.search.nodePort && !x.actionSelected
      );
    },
    actionSeletedChange() {
      this.reshowActionCheckedAll();
    }
  }
};
</script>

<style lang="scss" scoped>
.role-menu-list {
  background-color: white;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;

  .tools-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-search {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .label {
        font-size: 14px;
        margin-left: 15px;
      }

      .select {
        width: 200px;
        margin-right: 10px;
      }

      .role-name {
        font-size: 14px;
      }
    }

    .right-buttons {
      display: flex;
      align-items: center;

      .btn {
        margin-left: 15px;
      }
    }
  }

  ::v-deep(.table-ex) {
    margin-top: 20px;

    &.vxe-table--render-default .is--checked.vxe-cell--checkbox,
    &.vxe-table--render-default .is--checked.vxe-cell--checkbox .vxe-checkbox--icon,
    &.vxe-table--render-default .is--indeterminate.vxe-cell--checkbox,
    &.vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon,
    &.vxe-table--render-default .vxe-cell--checkbox .vxe-checkbox--icon:hover {
      color: #2861e2;
    }

    &.vxe-table--render-default .vxe-cell--checkbox:not(.is--disabled):hover {
      .vxe-checkbox--icon {
        color: #2861e2;
      }
    }

    .el-checkbox__inner:after {
      box-sizing: content-box !important;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 0 0;
  }

  .dialog-footer {
    text-align: right;
  }

  ::v-deep(.el-dialog__body) {
    padding: 13px;
  }
}
</style>
