
import request from '@/utils/request';

// 获取角色列表
export function getRoleList(data) {
  return request({
    url: "user-web-api/admin/role/getRolePage",
    method: 'POST',
    data,
  })
}

/**
 * 新增角色
 * @param data
 * @returns {AxiosPromise}
 */
export function addRole(data) {
  return request({
    url: "user-web-api/admin/role/addRole",
    method: 'POST',
    data,
  })
}

/**
 * 更新角色
 * @param data
 * @returns {AxiosPromise}
 */
export function updateRole(data) {
  return request({
    url: "user-web-api/admin/role/updateRole",
    method: 'POST',
    data,
  })
}

/**
 * 删除角色
 * @param data
 * @returns {AxiosPromise}
 */
export function deleteRole(data) {
  return request({
    url: "user-web-api/admin/role/delRole",
    method: 'POST',
    data,
  })
}

/**
 * 修改角色启用状态
 * @param data
 * @returns {AxiosPromise}
 */
export function setRoleStatus(data) {
  return request({
    url: "user-web-api/admin/role/updateIsDisable",
    method: 'POST',
    data,
  })
}

/**
 * 通过角色ID 获取角色权限
 * @param data
 * @returns {AxiosPromise}
 */
export function getUserPowerByRoleId(data) {
  return request({
    url: "user-web-api/admin/role/getRoleNodeListByRoleId",
    method: 'POST',
    data,
  })
}

export function saveRolePermission(data) {
  return request({
    url: "user-web-api/admin/role/setRoleNode",
    method: 'POST',
    data,
  })
}

/**
 * 获取角色列表无分页
 * @param data
 * @returns {AxiosPromise}
 */
export function getRoles(data) {
  return request({
    url: "user-web-api/admin/role/getRoleList",
    method: 'POST',
    data,
  })
}

// user-web-api/admin/role/updateBatchIsDisable
// 批量更新角色状态
export function setRlesState(data) {
  return request({
    url: "user-web-api/admin/role/updateBatchIsDisable",
    method: 'POST',
    data,
  })
}
export default {}
