<template>
  <el-select
    v-model="selectVal"
    :placeholder="placeholder"
    size="small"
    @change="change"
    ref="keySelect"
    :disabled="disabled"
    multiple
  >
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'BizLineSelect',
  props: {
    val: {
      type: Array,
      default: () => []
    },
    valArrStr: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择业务线'
    }
  },
  watch: {
    val: {
      immediate: true,
      handler(newVal) {
        this.selectVal = newVal;
      }
    }
  },
  data() {
    return {
      selectVal: [],
      options: [
        {
          label: '大片来了',
          value: 1
        },
        {
          label: 'JPG',
          value: 2
        }
      ]
    };
  },
  methods: {
    change() {
      this.$emit('update:val', this.selectVal);
      this.$emit('update:valArrStr', this.selectVal.join(','));
    }
  }
};
</script>

<style></style>
