import BizLineSelect from './BizLineSelect.vue';
import BrandSelect from './BrandSelect.vue';
import CityArea from './CityArea.vue';
import DepartmentSelect from './DepartmentSelect.vue';
import KeyArrSelect from './KeyArrSelect.vue';
import SexSelect from './SexSelect.vue';
import SourceChannel from './SourceChannel.vue';
import StoreSelect from './StoreSelect.vue';
import UserSelect from './UserSelect.vue';

export default {
  cityArea: { type: 'array', component: 'CityArea' },
  sourceChannel: { type: 'array', component: 'SourceChannel' },
  userSelect: { type: 'array', component: 'UserSelect' },
  sexSelect: { type: 'array', component: 'SexSelect' },
  departmentSelect: { type: 'array', component: 'DepartmentSelect' },
  brandSelect: { type: 'array', component: 'BrandSelect' },
  storeSelect: { type: 'array', component: 'StoreSelect' },
  bizLineSelect: { type: 'array', component: 'BizLineSelect' },
  chanceType: { type: 'keySelect', component: 'KeyArrSelect', reqKey: 'chance_type' }
};

export const components = {
  BizLineSelect,
  BrandSelect,
  CityArea,
  DepartmentSelect,
  KeyArrSelect,
  SexSelect,
  SourceChannel,
  StoreSelect,
  UserSelect
};
