import rawRequest from '@/utils/request';

const request = (options) => rawRequest({ ...options, allResult: true });

export function getCityList(data) {
  return request({
    url: 'misc-web-api/api/photo/city/findNotDisableList',
    method: 'POST',
    data: data
  });
}

export function getUserList(data) {
  return request({
    url: 'user-web-api/admin/user/info/platform/pageList',
    method: 'POST',
    data: data
  });
}

export function getChannelTree(data) {
  return request({
    url: 'misc-web-api/admin/channel/getChannelTree',
    method: 'POST',
    data
  });
}

export function getChannel(data) {
  return request({
    url: 'misc-web-api/admin/channel/getChannelList',
    method: 'POST',
    data
  });
}

export function getDepartments(data) {
  return request({
    url: 'user-web-api/admin/department/tree',
    method: 'POST',
    data
  });
}

export function getProductBrand(data) {
  return request({
    url: 'product-web-api/api/productBrand/list',
    method: 'POST',
    data
  });
}

export function getDictionary(data) {
  return request({
    url: 'misc-web-api/admin/dictionary/getDictionary',
    method: 'POST',
    data
  });
}

export function getStores(data) {
  return request({
    url: 'supplier-web-api/admin/store/getList',
    method: 'POST',
    data
  });
}
